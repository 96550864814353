<script setup lang="ts">
  import createTippy, { Instance } from 'tippy.js'
  import { onUnmounted, ref, watchEffect } from 'vue'
  import { defaultTooltipOptions } from '../config/tooltipConfig'

  const props = withDefaults(
    defineProps<{
      tippyOptions?: Partial<Instance['props']>
      hidden?: boolean
    }>(),
    { tippyOptions: undefined, hidden: false }
  )

  const tippy = ref<Instance>()
  const content = ref<HTMLDivElement>()
  const panel = ref<HTMLDivElement>()

  watchEffect(() => {
    tippy.value?.destroy()

    if (content.value && panel.value && !props.hidden) {
      tippy.value = createTippy(content.value, {
        ...defaultTooltipOptions,
        content: panel.value,
        allowHTML: true,
        ...props.tippyOptions,
      })
    }
  })

  onUnmounted(() => {
    tippy.value?.destroy()
  })

  defineExpose({
    tippy,
  })
</script>

<template>
  <div
    ref="content"
    data-testid="base-tooltip-content"
    tabindex="0"
    v-bind="$attrs"
  >
    <slot name="content" />
  </div>
  <div v-if="!hidden" ref="panel"><slot name="panel" /></div>
</template>
