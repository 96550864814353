import { Props as TippyProps } from 'tippy.js'

/**
 * v-tooltip directive default config
 */
export const defaultTooltipOptions: Partial<TippyProps> = {
  arrow: true,
  theme: 'dark',
  trigger: 'mouseenter focus',
  placement: 'bottom',
  appendTo: () => {
    const dialog = Array.from(
      document.querySelectorAll<HTMLDialogElement>('dialog')
    ).filter((dialog) => dialog.open)[0]

    return dialog ?? document.body
  },
  interactive: true,
  maxWidth: 272,
  offset: [0, 8],
  duration: [400, 300],
  popperOptions: {
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top'],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          // prevent tooltips from showing under the top navigation
          padding: { top: 56, right: 8, bottom: 32, left: 8 },
        },
      },
      {
        name: 'arrow',
        options: {
          padding: 12,
        },
      },
    ],
  },
}
